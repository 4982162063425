export default {
    Application: {
        Title: {
            Pods: 'PODS',
            CreatePods: '创建新的PODS',
            ActiveDialog: '启用PODS: {code}',
            Renew: '续费/购买服务',
        },
        Btn: {
            NewApp: '创建应用',
        },
        Link: {
            CheckRequirement: '查看硬件要求',
        },
        ListTable: {
            TH1: '应用',
            TH2: '版本/创建时间',
            TH3: '状态',
            TH4: '计费类型',
            TH5: '剩余可用SKU',
            TH6: '超级管理员',
            TH7: '操作',
            TD1: '{date} 到期',
            BtnResetPassword: '重置密码',
            BtnActive: '启用PODS',
            LinkToApp: '打开PODS',
            LinkRenew: '续费/购买服务',
            LinkToOfficial: '升级为正式版',
        },
        FormCreate: {
            H1: '选择版本',
            H2: '选择套餐',
            H3: '应用配置',
            H4: '费用',
            H5: '试用时长',
            H6: '购买时长',
            H7: '选择套餐',
            H8: '选择服务',
            H9: '应付',
            C1: 'PODS pro 免费试用版',
            C2: '个月',
            C3: '年',
            C4: '',
            C5: '正在计算...',
            Label1: '超级管理员',
            Label1Placeholder: '超级管理员登录用户名',
            Label2: '登录密码',
            Label2Placeholder: '至少6个字符',
            Label3: '再次输入密码',
            Label4: '应用别名',
            Label4Placeholder: '应用别名10个字符内',
            BtnSubmit: '确认下单',
            BtnActive: '确认启用',
            LinkToPrice: '查看价格表及服务明细',
        },
        FormRenew: {
            H1: '应用ID',
            H2: '应用名称',
            H3: '应用版本',
            H4: '到期时间',
            H5: '剩余可用SKU',
            H6: '产品',
            C1: '暂不续费',
            T1: '套餐续费',
            T2: '时长续费',
            T3: '购买服务',
            Alert: '试用版应用首次续费后自动升级为正式版'
        },
        Hint: {
            S1: '没有可用应用, 点击右上角「创建应用」按钮创建 ↗',
            S2: '应用别名10个字符内',
            S3: '两次输入密码不一致',
            S4: '价格查询失败, 请刷新页面重新',
        }
    }
}